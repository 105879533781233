import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="SumUp POS (formerly Goodtill) integration - Rotaready"
          description="An intelligent integration that enables sales transactions for every venue, department and stream to be imported into Rotaready automatically."
          url="integrations/goodtill-sumup"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="SumUp Point of Sale and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">An intelligent integration that enables sales transactions for every venue, department and stream to be imported into Rotaready automatically.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">This integration also enhances Rotaready’s demand forecasting feature; providing you with highly accurate, hourly sales forecasts for every site and department.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://sumup.co.uk/point-of-sale" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit SumUp POS" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshots of Rotaready and SumUp POS (formerly Goodtill)"
                  style={{ maxWidth: '500px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Analyse your labour percentage in real-time" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With the integration between Rotaready and SumUp POS (formerly Goodtill), Rotaready is able to display your hourly net sales within Cost Control and other reports." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="These sales figures will also be broken out by your streams (such as drink vs food) and displayed alongside the equivalent labour costs; allowing you to analyse your wage spend and labour percentage in real-time." />
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Ready to switch on the integration?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About SumUp POS" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="SumUp Point of Sale is a flexible, all-in-one tool, tailored to your every business need." />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Easy to use: SumUp POS is an intuitive tool that gives you full control over your time. Process orders, accept payments and get reports with ease." />
                    <TextListItem text="All-in-one tool: Everything you need to own your business, all in one place. SumUp POS offers a dynamic ecosystem of features you can rely on." />
                    <TextListItem text="Powerful integrations: Seamless ordering, delivery, loyalty and staff scheduling integrations allow you to take your business to new heights." />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <Text size="xl" text="With SumUp POS you can manage and control any type of business from one single place." />
              </TextBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about SumUp POS"
                  render={props => <a {...props} href="https://sumup.co.uk/point-of-sale" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/goodtill-sumup/hero-desktop.webp" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
